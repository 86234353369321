import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/css'
import 'swiper/css/bundle'
import "./Carousel.css"


const slider = [
    {
        url: "/Asserts/product/1.png"
    },
    {
        url: "/Asserts/product/2.png"
    },
    {
        url: "/Asserts/product/3.png"
    },
    {
        url: "/Asserts/product/4.jpg"
    }, {
        url: "/Asserts/product/5.jpg"
    },

];


const mm = [
    {
        url: "/Asserts/product/m1.jpeg"
    },
    {
        url: "/Asserts/product/m2.jpeg"
    },
    {
        url: "/Asserts/product/m3.jpeg"
    },
    {
        url: "/Asserts/product/m4.jpeg"
    },

];

const cp = [
    {
        url: "/Asserts/product/cp1.jpeg"
    },
    {
        url: "/Asserts/product/cp2.jpeg"
    },
    {
        url: "/Asserts/product/cp3.jpeg"
    },
    {
        url: "/Asserts/product/cp4.png"
    }

];

const Carousel = () => {
    return (
        <>
            <div>
                <div className='services-title'>
                    <p><code>&#8212;</code>Our Product</p>
                    <h1>Explore our Coco Peat</h1>
                </div>
                <div className='carousel'>
                    <div className='carousel-content'>
                        <p>
                            Cocopeat blocks are regarded as an excellent growing medium. The cocopeat powder obtained from the husk is sieved, washed, dried, and then compressed into blocks. It is versatile for a variety of planting needs and is also known for its natural rooting hormones and anti-fungal properties.
                        </p>
                        <ul>
                            <li>Excellent Water Retention</li>
                            <li>Aeration and Drainage</li>
                            <li>Natural Anti-Fungal Properties</li>
                            <li>Eco-Friendly and Sustainable</li>
                            <li>Nutrient-Rich</li>
                            <li>pH Neutral</li>
                            <li>Lightweight and Easy to Handle</li>
                        </ul>
                        <a href='/contact-us'>Enquire Now <i class="fa-solid fa-arrow-right"></i></a>
                    </div>

                    <Swiper
                        className='myswiper'
                        modules={[Pagination, EffectCoverflow, Autoplay]}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 3,
                            slideShadows: true
                        }}
                        loop={true}

                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 1
                            },
                            1024: {
                                slidesPerView: 2
                            },
                            1560: {
                                slidesPerView: 3
                            },
                        }}

                    >
                        {
                            cp.map((data, index) => (
                                <SwiperSlide style={{ backgroundImage: `url(${data.url})` }} className="myswiper-slider" key={index}>

                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                </div>
            </div>
            <div>
                <div className='services-title'>
                    <h1>Explore our Alum Crystals</h1>
                </div>
                <div className='carousel'>
                    <div className='carousel-content'>
                        <p>At Sivan Vel, we offer premium alum crystals known for their exceptional purity and quality. Sourced from the
                            finest natural reserves, our alum crystals are perfect for a wide range of uses,
                            from water purification and skincare to industrial applications.
                            Trust Sivan Vel for the best in natural alum crystals, meticulously selected and prepared for your needs.
                        </p>
                        <ul>
                            <li>Vaccines</li>
                            <li>Fabric Dyeing</li>
                            <li>Baking Powder</li>
                            <li>Deodorants</li>
                            <li>Antiseptics</li>
                            <li>After Shave</li>
                            <li>Beauty</li>
                            <li>Water Purification</li>
                        </ul>
                        <a href='/contact-us'>Enquire Now <i class="fa-solid fa-arrow-right"></i></a>
                    </div>

                    <Swiper
                        className='myswiper'
                        modules={[Pagination, EffectCoverflow, Autoplay]}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 3,
                            slideShadows: true
                        }}
                        loop={true}

                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 1
                            },
                            1024: {
                                slidesPerView: 2
                            },
                            1560: {
                                slidesPerView: 3
                            },
                        }}

                    >
                        {
                            slider.map((data, index) => (
                                <SwiperSlide style={{ backgroundImage: `url(${data.url})` }} className="myswiper-slider" key={index}>

                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                </div>
            </div>
            <div>
                <div className='services-title'>
                    <h1>Explore our Multani Mitti</h1>
                </div>
                <div className='carousel'>
                    <div className='carousel-content'>
                        <p>Multani mitti is classified as a sedimentary clay and is mostly constituted of the qualities silica, iron oxide, lime, magnesia, and water in extremely varied amounts.
                        </p>
                        <ul>
                            <li>Face for acne and pimples</li>
                            <li>Its antiseptic</li>
                        </ul>
                        <a href='/contact-us'>Enquire Now <i class="fa-solid fa-arrow-right"></i></a>
                    </div>

                    <Swiper
                        className='myswiper'
                        modules={[Pagination, EffectCoverflow, Autoplay]}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 3,
                            slideShadows: true
                        }}
                        loop={true}

                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 1
                            },
                            1024: {
                                slidesPerView: 2
                            },
                            1560: {
                                slidesPerView: 3
                            },
                        }}

                    >
                        {
                            mm.map((data, index) => (
                                <SwiperSlide style={{ backgroundImage: `url(${data.url})` }} className="myswiper-slider" key={index}>

                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                </div>
            </div>
        </>
    )
}

export default Carousel