import React, { useState } from 'react';
import "./Navbar.css";
import { Link } from 'react-router-dom';

export default function Navbar({ scrollToServices, bg_color }) {
    const [mobileView, setMobileView] = useState(false);

    return (
        <>
            <div className='mobile-navbar'>
                <i class="fa-solid fa-bars" onClick={() => { setMobileView(true); }}></i>
            </div>

            <div className={mobileView ? 'navbar-container open' : bg_color ? 'navbar-container bg' : "navbar-container"}>
                <div className='mobile-navbar close'>
                    <i class="fa-solid fa-xmark" onClick={() => { setMobileView(false); }}></i>
                </div>
                <Link className='navbar-items start' to='/'>
                    <img src='/Asserts/logo.png' alt='logo'></img>
                    <h1>Sivan<span>Vel</span></h1>
                </Link>
                <ul className='navbar-items mid'>
                    <li className='navbar-item'><a href='/' className='list-main'>Home</a></li>
                    <li className='navbar-item' onClick={() => { setMobileView(false); }}><Link to="/" onClick={scrollToServices} className='list-main'>Product</Link></li>
                    <li className='navbar-item'><Link to='/about-us' className='list-main'>About Us</Link></li>
                </ul>
                <div className='navbar-items end'>
                    <Link to="/contact-us">Contact Us</Link>
                </div>
            </div>
        </>
    );
}
