import React, { useRef } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import "./Home.css";
import { Player } from '@lottiefiles/react-lottie-player';
import Career from "./../../Asserts/career3.json";
import Footer from '../../Components/Footer/Footer';
import Carousel from '../../Components/Carousel/Carousel';

export default function Home() {
    const servicesRef = useRef(null);

    const scrollToServices = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className='home-container'>
            <Navbar scrollToServices={scrollToServices} />
            <div className='home-main-section'>
                <div className='home-main-container'>
                    <div className='home-title'>
                        <h1>Welcome to the <br /><span>Sivan Vel</span> International</h1>
                        <p>Experience the Power of Natural Purity and Versatility !</p>
                    </div>
                </div>
                <div className='home-background-wave'>
                </div>
            </div>
            <div className='about-us-section'>
                <div className='about-us-container'>
                    <div className='about-us-title'>
                        <p><code>&#8212;</code>About Us</p>
                        <h1>Who are we?</h1>
                    </div>
                    <div className='about-us-content'>
                        <p>Welcome to Sivan Vel, your trusted source for high-quality products like pure coco peat, alum crystals and Multani Mitti. Founded by V. Hanish Kumar and R. Seenu, we are committed to bringing the natural benefits of our products to customers worldwide.</p>
                        <a href="/about-us">Know More <i class="fa-solid fa-arrow-right"></i></a >
                    </div>
                </div>
            </div>
            <div className='services-section' ref={servicesRef}>
                <div className='services-container'>

                    <Carousel />
                </div>
            </div>
            <div className='get-in-touch-section'>
                <div className='get-in-touch-container'>
                    <div className='get-in-touch-inner-container'>
                        <h1>Still got a Question</h1>
                        <p>Feel free to contact us through any of the channels.</p>
                        <a href='/contact-us'>Contact Us <i class="fa-solid fa-arrow-right"></i></a>
                    </div>
                    <div className='career-player'>
                        <Player
                            autoplay
                            loop
                            src={Career}
                        />
                    </div>
                </div>
            </div>
            <div className='home-footer-wrapper'>
                <Footer />
            </div>
        </div >
    );
}
