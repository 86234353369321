import React from 'react';
import './Footer.css';

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();
    return (

        <div className="footer-copyright">
            <p>Copyright ©{year} sivanvelinternational.com | All rights reserved.</p>
        </div>
    );
}

export default Footer;
