import React, { useState } from 'react';
import "./Contact.css";
import Navbar from '../../Components/Navbar/Navbar';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import emailjs from 'emailjs-com';

export default function Contact() {
    const [changeColor, setChangeColor] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Custom validation checks
        if (!validateEmail(formData.email)) {
            alert('Please enter a valid email address.');
            return;
        }

        if (formData.phone && !validatePhone(formData.phone)) {
            alert('Please enter a valid phone number.');
            return;
        }

        setIsLoading(true);  // Start loading

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLETE_ID, formData, process.env.REACT_APP_EMAILJS_API_KEY)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Your message has been sent successfully!');
                setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
                setIsLoading(false);  // End loading
            }, (err) => {
                console.log('FAILED...', err);
                alert('Failed to send your message. Please try again.');
                setIsLoading(false);  // End loading
            });
    };

    const validateEmail = (email) => {
        // Basic email pattern validation
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const validatePhone = (phone) => {
        // Basic phone pattern validation
        const pattern = /^[0-9]{10}$/;
        return pattern.test(phone);
    };

    return (
        <div className='contact-us-main-section'>
            <Navbar />
            <div className='contact-us-main-container'>
                <div className='contact-us-main-title'>
                </div>
                <div className='contact-us-main-content-container-wrapper'>
                    <div className='contact-us-main-content-container'>
                        <div className='cu-info-container'>
                            <h1>Contact us through any of the channels listed below</h1>
                            <div className='cu-info-item'>
                                <h2>Address</h2>
                                <p>
                                    36A22 1st Street, Santhi Nagar West, Hosur – 635109 Krishnagiri, Tamilnadu, India.
                                </p>
                            </div>
                            <div className='cu-info-item'>
                                <h2>Phone</h2>
                                <p>+91 70921 88233 | +91 63793 99131</p>
                            </div>
                            <div className='cu-info-item'>
                                <h2>Email</h2>
                                <p>shivanvelinternational@gmail.com</p>
                            </div>
                            <div className='cu-horizontal'>
                            </div>
                            <ul className="socialIcon">
                                <li><Link to="#" style={{ marginLeft: "0px" }}><i className="fa-brands fa-facebook-square"></i></Link></li>
                                <li><Link to="#" ><i className="fa-brands fa-instagram"></i></Link></li>
                                <li><a href="https://wa.me/916379399131" target='__blank' ><i class="fa-brands fa-whatsapp"></i></a></li>
                                <li><Link to="#" ><i className="fa-brands fa-telegram"></i></Link></li>
                            </ul>
                        </div>
                        <form className='cu-form-container' onSubmit={handleSubmit}>
                            <div className='cu-form-item'>
                                <label className={changeColor === "name" ? 'focused' : ''}><i class="fa-solid fa-user"></i></label>
                                <input type='text' placeholder='Name' name='name' value={formData.name} onChange={handleChange} onBlur={() => { setChangeColor(false) }} onFocus={() => { setChangeColor("name") }} required />
                            </div>
                            <div className='cu-form-item'>
                                <label className={changeColor === "email" ? 'focused' : ''}><i class="fa-solid fa-envelope"></i></label>
                                <input type='email' placeholder='Email Address' name='email' value={formData.email} onChange={handleChange} onBlur={() => { setChangeColor(false) }} onFocus={() => { setChangeColor("email") }} required />
                            </div>
                            <div className='cu-form-item'>
                                <label className={changeColor === "phone" ? 'focused' : ''}><i class="fa-solid fa-phone"></i></label>
                                <input type='tel' placeholder='Phone Number' name='phone' value={formData.phone} onChange={handleChange} onBlur={() => { setChangeColor(false) }} onFocus={() => { setChangeColor("phone") }} pattern="[0-9]{10}" title="Phone number should be 10 digits" required />
                            </div>
                            <div className='cu-form-item'>
                                <label className={changeColor === "subject" ? 'focused' : ''}><i class="fa-solid fa-circle-exclamation"></i></label>
                                <input type='text' placeholder='Subject' name='subject' value={formData.subject} onChange={handleChange} onBlur={() => { setChangeColor(false) }} onFocus={() => { setChangeColor("subject") }} required />
                            </div>
                            <div className='cu-form-item text'>
                                <label className={changeColor === "body" ? 'focused' : ''}><i class="fa-solid fa-pen"></i></label>
                                <textarea type='text' placeholder='How can we help you? Feel free to get in touch!' name='message' value={formData.message} onChange={handleChange} onBlur={() => { setChangeColor(false) }} onFocus={() => { setChangeColor("body") }} required />
                            </div>
                            <div className='cu-form-item'>
                                <button type='submit' disabled={isLoading}>
                                    {isLoading ? 'Sending...' : 'Send Us'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
