import React from 'react';
import "./About.css"
import Navbar from '../../Components/Navbar/Navbar';
import { Player } from '@lottiefiles/react-lottie-player';
import AboutUs from "./../../Asserts/about-us.json";
import Footer from '../../Components/Footer/Footer';

export default function About() {
    return (
        <div className='about-us-km-section'>
            <Navbar />
            <div className='about-us-km-container'>
                <div className='aboutus-heading'>
                    <h1>About <span>Us</span></h1>
                </div>
                <div className='about-us-kn-section'>
                    <div>
                        <h1>Sivan Vel <span>International</span></h1>
                    </div>
                    <div className='about-us-kw-content-container'>

                        <div className='about-us-content-km'>
                            <p>
                                Welcome to Sivan Vel International, your trusted source for high-quality natural products, including Coco Peat, Alum Crystals and Multani Mitti. Founded by V. Hanish Kumar and R. Seenu, we are dedicated to delivering the natural benefits of our products to customers worldwide.
                            </p>
                            <p>
                                <b>V. Hanish Kumar</b>, a B.com graduate from Christ College Malur, and <b>R. Seenu</b>, a B.B.A graduate from Christ Malur 2024, have brought together their expertise and passion to create a brand that stands for excellence and purity. At Sivan Vel, we meticulously source the finest natural products, ensuring they meet our high standards of quality.
                            </p>
                            <p>
                                Our mission is to provide you with superior products for a range of applications, from personal care to industrial use. We proudly export our premium products internationally, sharing their natural benefits with the world.
                            </p>
                            <p>
                                Thank you for choosing Sivan Vel. Experience the essence of natural quality with us!
                            </p>
                        </div>

                        <div className='about-us-player-km'>
                            <Player
                                autoplay
                                loop
                                src={AboutUs}
                            />
                        </div>
                    </div>
                </div>
                <div className='vision-section'>
                    <div className='vision-container'>
                        <div className='vision-title'>
                            <h1>Our Vision</h1>
                        </div>
                        <div className='vision-content'>
                            <p>To be globally recognized export company that priorizes the quality, the service, and the value in order to satisfy the needs of our customers</p>

                        </div>
                    </div>
                </div>
                <div className='mision-section'>
                    <div className='mision-container'>
                        <div className='mision-title'>
                            <h1>Our Mision</h1>
                        </div>
                        <div className='mision-content'>
                            <p>We strive to provide an end-to-end export and import service that is of superior quality and cost-effective, with strong customer focus and continuous improvement.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
